.profile-container {
    border: 2px solid #262931;
    border-radius: 10px;
    margin: 20px;
    background-color: #f4f5f4;
    max-width: 1200px;
}

.bio {
    margin-left: 15px
}

.question {
    font-weight: bolder;
    
    padding-top: 10px;
    margin-left: 15px;
    
}

.answer {
    margin-left: 20px;
}

.section {
    margin: 20px 0
}

.header {
    /* border-bottom: 2px solid #26293150; */
    font-weight: bolder;
    padding-left: 10px;
    padding-bottom: 10px;
}

.coding-journey {
    margin: 20px;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    position: relative;
    overflow: hidden;
}

.experience {
    display: flex;
    align-items: center;
    
}

.first {
    margin-bottom: 25px;
}

.middle {
    margin: 25px 0;
}

.last {
    margin-top: 25px;
}



.experience-description {
    margin-left: 10px;
}

.company-logo {
    flex-shrink: 0;
    object-fit: cover;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    background-color: #FFFFFF;
    border: 2px solid #262931;
    z-index: 2;
}

.seperator {
    position: absolute;
    height: 9000px;
    border: 1px solid black;
    width: 0px;
    margin-left: 40px;
}


.year {
    font-weight: 900;
    font-size: 1.1em;
}