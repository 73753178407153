.portfolio-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 100%;
    padding: 10px;
}

.portfolio-card {
    display: flex;
    flex-direction: column;
    background-color: #efefea;
    max-width: 310px;

    text-align: center;
    /* border: 2px solid #cccccc; */
    margin: 10px 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #9b9b97;

}

.project-title {
    background-color: #262931;
    color: #F4F3F3;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px;
    font-size: 0.9em;
    font-weight: bolder;
}

.project-img-preview {
    max-height: 350px;
    max-width: 270px;
    margin-top: 10px;
    border-radius: 10px;
    border: 2px solid rgba(0,0,0,0);
    cursor: pointer;
    transition: all 0.3s;
}

.project-img-preview:hover {
    border: 2px solid #262931;
    box-shadow: 1px 1px 5px #262931;
}

.img-label {
    font-size: 0.7em;
    margin-bottom: 10px;
    font-weight: lighter;
    cursor: pointer;
}

@keyframes createBox {
    from {
      transform: scale(0);
      z-index: 2;
    }
    to {
      transform: scale(1);
      z-index: 2;
    }
  }

.glass-box {
    padding-top: 50px;
    display: block;
    text-align: center;
    animation: createBox 0.1s;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.8);
    z-index: 2;
    overflow: scroll;
}

.glass-box-label {
    z-index: 2;
    color: #f4f3f3;
}

.full-img {
    margin: 10px auto;
    max-width: 90vw;
    

    z-index: 2; 
    
}

.tech-index {
    background-color: #efefea;
    width: 90%;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0px 0px 5px #9b9b97
}

.tech-index-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 10px;
}

.tech-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tech-item {
    margin: 0px 10px;
}


.tech-logo {
    max-height: 50px;
}

.zoom-toggle:checked ~ label .project-img-preview {
    width: auto;
    max-width: 90vw;
    height: auto;
    padding-right: auto;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 2; 
    overflow: scroll;
}

.links {
    display: flex;
    flex-direction: column;
    height: 130px;
    width: 100%;
    justify-content: space-evenly;
}

.link {
    transition: all 0.4s;
    color: #262931;
    background-color: #efefea;
    margin-top: auto; 
    border: 1px solid #262931;
    padding: 5px;
    border-radius: 5px;
}

.link:hover {
    background-color: #262931;
    color: #efefea;
    box-shadow: 1px 1px 5px #262931;
}

.notes {
    align-self: flex-start;
    justify-self: flex-start;
    text-align: left;
    padding-right: 20px;
    font-size: 0.8em;
}

.inline-link {
    text-decoration: underline;
}

li {
    margin-top: 5px;
}