nav {
    height: 80px;
    background-color: #cccccc;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-weight: bolder;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262931' fill-opacity='0.1'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.cv-link {
    font-size: 0.7em;
    align-items: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
}

.icon {
        height: 1.2em;
}

.menu-icon {
    /* border: 3px solid black; */
    height: 40px;
    padding: 5px;
    margin: auto 20px;
    border-radius: 10px;
    transition: all 0.2s ease;
}

.dropdown {
    height: 100%;
    position: relative;
    display: inline-block;
    border-radius: 10px;
}

.nav-content {
    display: block;
    position: absolute;
    background-color: #F4F3F3;
    min-width: 160px;
    width: 98%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    
}


Link {
    text-decoration: none;
}

.nav-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #262931;
    background-color: #F4F3F3;
    border-radius: 7.5px;
    margin: 10px 5px;
    padding: 5px;
    transition: all 0.2s ease;
    cursor: pointer;
}

.menu-icon-element {
    border-radius: 5px;
    width: 35px;
    height: 5px;
    background-color: #262931;
    margin: 6px 0;
}

.job-title {
    margin-right: auto;
    margin-left: 20px;   
}

.nav-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    transition: all 0.2s ease;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.nav-container.visible {
    background-color: #f4f3f3;
}

.nav-label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    height: 100%;}

/* .dropdown:hover .nav-container {
    box-shadow: 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
    background-color: #f4f3f3;
} */

/* .dropdown:hover .menu-icon {
    background-color: #f4f3f3;
} */

a {
    text-decoration: none;
    margin: 0px;
    
    color: #262931
}

.nav-item:hover {
    color: #F4F3F3;
    background-color: #262931;
}

