.contact-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.contact-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px;
    width: 200px;
    height: 140px;
    padding: 20px;
}

#mail-full {
    background-color: #5e5e5e;
    transition: all 0.5s ease;
}

.social-full {
    outline: 2px solid #15171b;
    border: 3px solid #15171b;
    outline-offset: -4px;
    border-radius: 50%;
    max-height: 100px;
    margin-bottom: 10px;
    background-color: #f4f3f3;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);
    filter: gray; 
}

.social-full:hover {
    filter: none;
    cursor: pointer;
}

#github-full {
    background-color:#5e5e5e;
    transition: background-color 0.5s ease;
}

.github:hover #github-full {
    background-color: #15171b;
}

#linkedin-full {
    /* outline: 1px solid #15171b; */
    border: 3px solid #15171b;
    transition: all 0.2s ease;
    filter: #777777;
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);
}

.linkedin:hover #linkedin-full{
    filter: none;
    -webkit-filter: grayscale(00%);
    -moz-filter:    grayscale(00%);
    -ms-filter:     grayscale(00%);
    -o-filter:      grayscale(00%);
    cursor: pointer;
}

.mail:hover #mail-full {
    background-color:#000000;
}